import React from 'react'
import '../common_styles/common.css';
import css from './articles.module.css';
import Cover from "../components/articles/cover";
import Contents from "../components/articles/contents";
import Link from "gatsby-link";
import ImageView from "../framework/alto/ui/image_view";
import navigation from "../images/navigation.png";
import "../common_styles/common.css";

const ArticlesPage = () => {

    return (
        <div className={css.base}>
            <section className={css.toolbar}>
                <Link to="/" className={css.linkHome}>
                    <ImageView className={css.navigation} src={navigation} alt={'navigation'}/>
                </Link>

                <div className={css.title}>
                    <span>Articles</span>
                </div>

                <div className={css.subTitle}>
                    <span>Latest: Trees</span>
                </div>
            </section>

            <section className={css.body}>

                <Cover />
                <Contents />
            </section>
        </div>
    )

};

export default ArticlesPage;