import React from 'react';
import styles from './contents.module.css';
import webArticlesController from "../../controllers/web_articles_controller";
import appleNativeArticlesController from "../../controllers/apple_native_artles_controller";
import computerScienceArticlesController from "../../controllers/computer_science_articles_controller";
import ListView from "../../framework/alto/ui/list_view";
import ContentsCell from "./contents-cell";
import useController from "../../framework/alto/data/useController";

const Contents = () => {
    const [webArticlesData] = useController(webArticlesController);
    const [appleNativeArticlesData] = useController(appleNativeArticlesController);
    const [computerScienceArticlesData] = useController(computerScienceArticlesController);

    return (

        <div className={styles.contents}>
            <div className={styles.documentFrame}>
                <header className={styles.header}>
                    <div className={styles.contentFrame}><span className={styles.contentTitle}>Contents</span></div>
                </header>

                <section className={styles.section}>
                    <div className={styles.sectionHeader}><span>Web</span></div>
                    <ListView className={styles.listView} data={webArticlesData.collection} id="url">
                        <ContentsCell/>
                    </ListView>
                </section>

                <section className={styles.section}>
                    <div className={styles.sectionHeader}><span>Apple Native</span></div>
                    <ListView className={styles.listView} data={appleNativeArticlesData.collection} id="url">
                        <ContentsCell/>
                    </ListView>
                </section>

                <section className={styles.section}>
                    <div className={styles.sectionHeader}><span>Computer Science</span></div>
                    <ListView className={styles.listView} data={computerScienceArticlesData.collection} id="url">
                        <ContentsCell/>
                    </ListView>
                </section>

            </div>
        </div>
    )

};

export default Contents;