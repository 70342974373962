import Controller from "../framework/alto/data/controller";

let appleNativeArticlesController = Controller({

    collection: []

});

/*
{
        title: "Hello Classkit",
        date: "06/10/2018",
        author: "Chad Eubanks",
        url: "/articles/hello-classkit"
    }
 */

export default appleNativeArticlesController;